<template>
  <div class="wrapper">
    <div class="row">
      <h3>Добавление сотрудника</h3>
    </div>


    <div class="row">
      <div class="col-md-10 col-lg-6">
        <div class="info-text" v-if="!rolesList.services.length">
          Вам доступно добавление только суперадмина. Вы не сможете добавить сотруднику другие роли, так как вы не
          подключились ни к одному из сервисов.
        </div>
        <form @submit.prevent novalidate>
          <InputGroup :labelTxt="'Фамилия*'"
                      :inputId="'1'"
                      :errorTxt="userErrorTextByName('last_name') || lastNameErr"
                      v-model="lastName" :val="lastName" :isDisabled="formSent"/>

          <InputGroup :labelTxt="'Имя*'"
                      :inputId="'2'"
                      :errorTxt="userErrorTextByName('name') || nameErr"
                      v-model="name" :val="name" :isDisabled="formSent"/>

          <InputGroup :labelTxt="'Отчество*'"
                      :inputId="'3'"
                      :errorTxt="userErrorTextByName('second_name') || middleNameErr"
                      v-model="middleName" :val="middleName" :isDisabled="formSent"/>


          <InputGroup :labelTxt="'Электронная почта*'"
                      :inputId="'4'"
                      :errorTxt="userErrorTextByName('email') || emailErr"
                      v-model="email" :val="email" :isDisabled="formSent"/>

          <div class="input-group select">
            <label for="inputId"> Филиал* </label>


            <select name="filial" :disabled="formSent" id="inputId" required v-model="filials">
              <option disabled value="">Выберите один из вариантов</option>
              <option v-for="(item, index) in rolesList.filials" :key="index" :value="item.id">
                {{ item.name }}
              </option>

              <option v-if="!rolesList.filials.some(e => e.id == companyId)" :value="companyId"
                      :selected="!rolesList.filials.length"> {{ companyName }}
              </option>
            </select>
            <p class="err" v-if="filialErr"> {{ filialErr }} </p>

          </div>


          <div class="row align-center space-between" v-if="!formSent">
            <a class="btn btn-bg" @click="sendForm1" :class="{ 'disabled': sending1}">Далее</a>
            <router-link to="/employers">Отменить</router-link>
          </div>

          <div class="role" v-if="formSent">
            <h3>Роль пользователя в системе</h3>

            <div class="checkbox row">
              <input type="checkbox" id="checkbox" v-model="superAdmin">
              <label for="checkbox">сделать суперадмином</label>
            </div>

            <div class="services" v-if="!superAdmin && rolesList.services.length">
              <div class="row headers">
                <div class="col-6">Система*</div>
                <div class="col-6">Роль*</div>
              </div>

              <div class="row service" v-for="(item, index) in rolesList.services" :key="index">
                <div class="col-sm-6">
                  <div class="checkbox service_left_col">
                    <input type="checkbox" :id="item.id" v-model="services" :value="item.id">
                    <label :for="item.id"> {{ item.name }} </label>
                  </div>
                </div>

                <div class="col-sm-6" v-if="isServiceChecked(item.id)">
                  <div class="select">
                    <select name="role" required @change="addRoles($event, item)" v-if="rolesList.roles.length">
                      <!--                  <option disabled selected value="Выберите роль">Выберите роль</option>-->
                      <option v-for="(item1, index) in rolesList.roles" :key="index" :value="item1.id"
                              :selected="index === 0 || (roles.some(e => e.service.id === item.id && e.role.id == item1.id))">
                        {{ item1.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <p class="err"></p>
              </div>

            </div>
            <div class="row align-center space-between">
              <a class="btn btn-bg" @click="sendForm2" :class="{ 'disabled': sending2 || !isServicesChecked}">Далее</a>
              <a @click="cancel">Отменить</a>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import InputGroup from "@/components/form/InputGroup";
import {mapGetters} from "vuex";

export default {
  name: "AddUser",
  data() {
    return {
      lastName: "",
      lastNameErr: "",
      name: "",
      nameErr: "",
      middleName: "",
      middleNameErr: "",
      email: "",
      emailErr: "",
      filials: "",
      filialErr: "",
      roles: [],
      services: [],
      superAdmin: false,
      crmId: "",
      id: "",
      userId: "",
      passportId: "",
      contactId: "",
      formSent: false,
      sending1: false,
      sending2: false
    }
  },
  components: {InputGroup},
  computed: {
    companyId() {
      return this.$store.getters.company.companyId;
    },
    ...mapGetters([
      'userErrorTextByName',
      'userErrorByName'
    ]),
    companyName() {
      return this.$store.getters.companyName;
    },
    rolesList() {
      return this.$store.getters.userRoles.data;
    },
    isServicesChecked() {
      return this.services && this.services.length > 0 || this.superAdmin;
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.sending2 = true;
      this.$store.dispatch('getUsers').then((response) => {

        let init = this;
        let user = this.$store.getters.userById(this.$route.params.id);

        this.formSent = true;
        this.name = user.name;
        this.nameErr = "";
        this.lastName = user.lastName;
        this.lastNameErr = "";
        this.middleName = user.middleName;
        this.middleNameErr = "";

        this.contactId = user.contactId;
        this.id = this.$route.params.id;
        this.email = user.email;
        this.filials = user.filialId;
        this.superAdmin = user.superadmin;
        this.roles = [];
        this.services = [];

        if (!user.superadmin) {
          this.services = user.roles.map(obj => {
            return obj.serviceId;
          });


          this.services.forEach(service_id => {
            if (!init.roles.some(obj => {
              return service_id === obj.service.id
            })) {
              let role_i = user.roles.find(obj => obj.serviceId === service_id);

              this.roles.push({
                service: {
                  id: service_id,
                  name: role_i.service
                }, role: {
                  id: role_i.roleId,
                  name: role_i.roleName,
                  rowRoleId: role_i.rowRoleId
                }
              });
            }
          });
        }
        this.sending2 = false;
      });
    }
    this.$store.dispatch('setRolesData');
  },


  methods: {
    isServiceChecked(id) {
      return this.services.some(e => e === id);
    },
    addRoles(event, service) {
      if (this.roles.find(e => e.service.id === service.id) === undefined) {
        let r = this.rolesList.roles.find(obj => obj.id === event.target.value);
        this.roles.push({service: service, role: r, rowRoleId: 0});
      } else {
        let index = this.roles.findIndex(obj => obj.service.id === service.id);
        let r = this.rolesList.roles.find(obj => obj.id === event.target.value);
        var rowId = this.roles[index].role.rowRoleId;
        this.roles[index].role = r;
        this.roles[index].role.rowRoleId = rowId;
      }
    },
    async sendForm1() {
      if (this.sending1)
        return;

      let init = this;
      this.sending1 = true;

      let errtxt = "Поле обязательно для заполнения";
      let check_req = false;

      this.nameErr = "";
      this.lastNameErr = "";
      this.middleNameErr = "";
      this.emailErr = "";
      this.filialErr = "";

      if (this.name.trim() === "") {
        this.nameErr = errtxt;
        check_req = true;
      }

      if (this.lastName.trim() === "") {
        this.lastNameErr = errtxt;
        check_req = true;
      }

      if (this.middleName.trim() === "") {
        this.middleNameErr = errtxt;
        check_req = true;
      }
      if (this.email.trim() === "") {
        this.emailErr = errtxt;
        check_req = true;
      }
      if (this.filials === "" && this.rolesList.filials.length) {
        this.filialErr = errtxt;
        check_req = true;
      } else if (!this.rolesList.filials.length)
        this.filials = this.companyId;

      if (check_req) {
        this.sending1 = false;
        return;
      }

      //let filialName = this.rolesList.filials.find(obj => obj.id === init.filials).name;

      await this.$store.dispatch('sendNewUser', {
        "name": init.name,
        "last_name": init.lastName,
        "second_name": init.middleName,
        "email": init.email,
        // "filialId": init.filials,
        // "filialName": filialName,
      }).then((response) => {
        this.sending1 = false;
        if (!response.errors.length && response.status !== "error") {
          init.formSent = true;
          init.passportId = response.data.passportId;
          init.userId = response.data.userId;
          init.contactId = response.data.contactId;
        }
      }).catch((error) => {
        this.sending1 = false;
        console.log(error);
      });
    },
    async sendForm2() {
      if (!this.isServicesChecked) {
        return;
      }
      if (this.sending2)
        return;

      let init = this;
      let new_roles = [];
      let errtxt = "Поле обязательно для заполнения";
      this.sending2 = true;

      init.services.forEach(e => {
        if (!init.roles.some(obj => {
          return e === obj.service.id
        })) {

          this.roles.push({
            service: {
              id: e,
              name: init.rolesList.services.find(i => i.id === e).name
            }, role: this.rolesList.roles[0]
          });
        }
      });

      if (init.superAdmin) {
        new_roles = [];
        if (init.contactId === "") {
          this.sending2 = false;
          return;
        }
      } else {
        new_roles = init.roles.filter(obj => {
          return init.services.some(e => {
            return e === obj.service.id
          })
        });
      }


      if (this.filials === "" && this.rolesList.filials.length) {
        this.filialErr = errtxt;
      } else if (!this.rolesList.filials.length)
        this.filials = this.companyId;


      // update
      if (this.$route.params.id !== undefined) {
        var roless = new_roles.map(obj => {
          return {
            "rowRoleId": (obj.role.rowRoleId == undefined ? 0 : parseInt(obj.role.rowRoleId)),
            "serviceId": parseInt(obj.service.id),
            "roleId": parseInt(obj.role.id),
          }
        })

        let str = "";
        for (let i = 0; i < roless.length; i++) {
          str += "&roles[" + i + "][roleId]=" + roless[i].roleId + "&roles[" + i + "][serviceId]=" + roless[i].serviceId
              + "&roles[" + i + "][rowRoleId]=" + roless[i].rowRoleId;
        }


        if (!roless.length)
          str = "&roles=[]";


        await this.$store.dispatch('updateUser', {
          // "passportId": init.passportId,
          // "userId": init.userId,
          // name: init.name,
          // "last_name": init.lastName,
          // "second_name": init.middleName,
          // "email": init.email,
          // "superadmin": init.superAdmin,
          "contactId": init.contactId,
          "filialId": parseInt(init.filials),
          "roles": str
        }).then((response) => {
          this.sending2 = false;
          if (!response.errors.length) {
            init.formSent = true;
            this.$router.push('/employers');
          }
        });
      }
      // adding new
      else {
        if (init.superAdmin)
          init.superAdmin = 1;
        else
          init.superAdmin = 0;

        var roless = new_roles.map(obj => {
          return {
            "serviceId": parseInt(obj.service.id),
            "roleId": parseInt(obj.role.id),
          }
        })

        let str = "";
        for (let i = 0; i < roless.length; i++) {
          str += "&roles[" + i + "][roleId]=" + roless[i].roleId + "&roles[" + i + "][serviceId]=" + roless[i].serviceId;
        }

        if (!roless.length)
          str = "&roles=[]";

        await this.$store.dispatch('sendUser', {
          // "passportId": init.passportId,
          // "userId": init.userId,
          // name: init.name,
          // "last_name": init.lastName,
          // "second_name": init.middleName,
          // "email": init.email,
          "superadmin": init.superAdmin,
          "contactId": init.contactId,
          "filialId": parseInt(init.filials),
          "roles": str
        }).then((response) => {
          this.sending2 = false;
          if (!response.errors.length) {
            init.formSent = true;
            this.$router.push('/employers');
          }
        });
      }


    },
    cancel() {
      if (this.$route.params.id == undefined) {
        this.name = "";
        this.lastName = "";
        this.middleName = "";
        this.email = "";
        this.filials = "";

        this.$store.commit("resetCheckData");

        this.formSent = false;
      } else {
        this.$router.push('/employers');
      }

    }
  }
}
</script>

<style scoped lang="scss">
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

.info-text {
  padding-bottom: 30px;
  color: #e5221c;
}

h3 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 32px;
}

.role {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  h3 {
    margin-top: 64px;
  }
}

form {

  font-size: 18px;
  line-height: 1.56;

  a {
    cursor: pointer;
  }

  p.err {
    color: #e5221c;
    margin-top: 10px;
    font-size: 16px;
    line-height: 26px;
  }

  .btn {
    width: 100%;
    max-width: 350px;
  }

  .checkbox {
    margin-bottom: 32px;
    white-space: nowrap;
    display: inline-flex;

    label {
      cursor: pointer;
      white-space: normal;
    }
  }

  .checkbox input {
    margin-right: 16px;
    cursor: pointer;
  }

  .select {
    position: relative;

    select::-ms-expand {
      display: none;
    }
  }

  .service_left_col {
    padding-right: 10px !important;
  }

  .services .headers {
    margin-bottom: 13px;
  }

  .service {
    margin-bottom: 46px;


    label {
      font-size: 22px;
      line-height: 100%;
    }

    .checkbox {
      margin-bottom: 0;
    }

    .select:after {
      bottom: -42px;
      top: 18px;
    }
  }


  .select:after {
    content: "\f104";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #1787d8;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    right: 19px;
    /*Adjust for position however you want*/

    top: 54px;
    /*left line */

    position: absolute;
    pointer-events: none;
  }


  .select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add some styling */

    display: block;
    width: 100%;
    float: right;
    background-image: none;
    border: 1px solid #cccccc;
    -ms-word-break: normal;
    word-break: normal;
  }


  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    label {
      color: $navy;
      margin-bottom: 11px;
    }


    p {
      color: #e5221c;
      margin-top: 10px;
      font-size: 16px;
      line-height: 26px;
    }
  }

  input, select {
    font-size: 18px;
    color: $text-gray;
    padding: 18px 23px;
    border: 1px solid $light-border;
    border-radius: 8px;
  }

  select {
    padding-right: 34px;
  }

  .icon {
    display: none;
  }

  input:focus, select:focus {
    outline: none;
    color: #000;
  }


  @include sm-max {
    .checkbox label {
      margin-bottom: 10px;
    }
  }


}


@include md-max {

  .btn-bg {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  form {
    font-size: 16px;

    .input-group {
      margin-bottom: 10px;
    }
  }

}

</style>
